
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import ContactMeForm from './ContactMeForm';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Navbar_Links from './index'

function Footer() {
    return (
        <div className="footer">
            <div className='footer-logo'>
                <h3>Alejandra's Stetic</h3>
                <p>Teléfono: +1 (936)-446-9288</p>
            </div>
            <div className='footer-social-media'>
                <p>Síguenos</p>
                <div className='footer-icons'>
                    <div className='icons-ii'>
                        <a href='https://api.whatsapp.com/send?phone=50259690471'>
                        <FontAwesomeIcon className='fa-globe' icon={faWhatsapp} />
                        </a>
                    </div>
                    <div className='icons-ii'>
                        <a href='https://instagram.com'>
                            <FontAwesomeIcon className='fa-globe' icon={faInstagram} />
                        </a>
                    </div>
                    <div className='icons-ii'>
                        <a href='https://www.facebook.com/profile.php?id=100088365317931'>
                        <FontAwesomeIcon className='fa-globe' icon={faFacebook} />
                        </a>
                    </div>
                </div>
            </div>
            <Navbar_Links/>
        </div>
    );
}

export default Footer;